import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  IconButton,
  ListSubheader,
  MenuItem,
  MenuList,
  Checkbox,
  ListItemText,
} from '@mui/material';
import styled from '@/styles';
import { Fragment, useState } from 'react';
import { theme } from '@/styles/colors';
import Button from '@/elements/Button';
import { Item } from './AccountsSelect';

interface Props {
  title: string;
  items: Item[];
  initialSelection?: string[];
  hideModal: () => void;
  updateFilters?: (selectedItems: string[]) => void;
  selectAll?: boolean;
}

const styles = {
  checkbox: {
    borderRadius: '20px',
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
};

const Menu = styled(MenuList, {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  li: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    color: '#1C1C1C',
    fontSize: '$lg',
    padding: '0',
    minHeight: '24px',
    svg: {
      height: '24px',
      width: '24px',
      margin: '0',
    },
  },
});

const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

const AccountOptionsModal = ({
  title,
  items,
  hideModal,
  updateFilters,
  initialSelection,
  selectAll,
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(
    initialSelection ? initialSelection : [],
  );

  const onOptionClick = (id: string) => {
    setSelectedItems((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const onSelectAll = () => {
    const allAccountIds = items.flatMap((customer) =>
      customer.accounts.map((account) => account.id),
    );

    if (selectedItems.length === allAccountIds.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allAccountIds);
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          height: '50vh',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <HeaderDialog>
        <TitleDialog> {title}</TitleDialog>
        <IconButton aria-label="close" onClick={hideModal}>
          <CloseOutlined />
        </IconButton>
      </HeaderDialog>
      <DialogContent
        dividers
        style={{ padding: '0px 25px 25px', border: 'none' }}
      >
        <Menu
          sx={{ paddingTop: '10px', paddingBottom: '0px', overflowY: 'auto' }}
        >
          {selectAll && (
            <MenuItem onClick={() => onSelectAll()}>
              <Checkbox
                checked={
                  selectedItems.length ===
                  items.flatMap((customer) => customer.accounts).length
                }
                sx={styles.checkbox}
              />
              <ListItemText
                primary="Select All"
                primaryTypographyProps={styles.menuItem}
              />
            </MenuItem>
          )}
          {items.map((customer) => (
            <Fragment key={customer.id}>
              <ListSubheader
                style={{
                  fontSize: '.875rem',
                  fontWeight: 600,
                  color: theme.themeDefault,
                }}
              >
                {customer.clientName}
              </ListSubheader>
              {customer.accounts.map((account) => (
                <MenuItem
                  key={account.id}
                  value={account.id}
                  onClick={() => onOptionClick(account.id)}
                >
                  <Checkbox
                    checked={selectedItems.includes(account.id)}
                    sx={styles.checkbox}
                  />
                  <ListItemText
                    primary={account.name}
                    primaryTypographyProps={styles.menuItem}
                  />
                </MenuItem>
              ))}
            </Fragment>
          ))}
        </Menu>
      </DialogContent>
      <Button
        style={{
          width: '80%',
          marginTop: '10px',
          marginBottom: '20px',
          alignSelf: 'center',
        }}
        onClick={() => {
          updateFilters && updateFilters(selectedItems);
          hideModal();
        }}
      >
        Apply
      </Button>
    </Dialog>
  );
};

export default AccountOptionsModal;
