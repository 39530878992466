import React, { useEffect } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { theme, text } from '@/styles/colors';
import { Scalars } from '@/types/graphql';
import { useAppSelector } from '@/hooks/store';

const styles = {
  menuItem: {
    height: '48px',
    color: text.textDefault,
    '&.Mui-selected': {
      backgroundColor: 'inherit',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'inherit',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'inherit',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  select: {
    height: 36,
  },
  menuProps: {
    autoFocus: false,
    PaperProps: {
      style: {
        height: '192px',
      },
    },
  },
};

const RegionSelect = ({
  regions,
  setBookMarked,
  setSelectedRegions,
  selectedRegions,
}: {
  regions: Array<{ label: string; value: Scalars['ID']['input'] }>;
  setBookMarked: (arg: boolean) => void;
  setSelectedRegions: (arg: Array<Scalars['ID']['input']>) => void;
  selectedRegions: Array<Scalars['ID']['input']>;
}) => {
  const regionIdsInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.regionIds,
  );
  useEffect(() => {
    setSelectedRegions(regionIdsInStore || []);
  }, [regionIdsInStore]);

  const handleChange = (
    event: SelectChangeEvent<Array<Scalars['ID']['input']>>,
  ) => {
    const value = event.target.value as Array<Scalars['ID']['input']>;
    setSelectedRegions(value);
    setBookMarked(false);
  };

  const getDisplayValue = (selected: string[]) => {
    return <span style={{ width: text.textDefault }}>Select Region</span>;
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="multi-select-label"
        label="Select Region"
        multiple
        displayEmpty
        value={selectedRegions}
        onChange={handleChange}
        renderValue={getDisplayValue}
        fullWidth
        input={<OutlinedInput />}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={styles.select}
        MenuProps={styles.menuProps}
      >
        {regions.map((region) => (
          <MenuItem
            key={region.value}
            value={region.value}
            sx={styles.menuItem}
          >
            <Checkbox
              checked={selectedRegions.includes(region.value)}
              sx={styles.checkbox}
            />
            <ListItemText primary={region.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RegionSelect;
