import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { JobStatusEnum } from '@/types/graphql';
import theme from '@/styles/colors';
import { useAppDispatch } from '@/hooks/store';
import { setDashboardJobStatus } from '@/store/slices/filtersSlice';
import { useAppSelector } from '@/hooks/store';
import { Typography } from '@mui/material';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import useAuth from '@/hooks/useAuth';
import { OptionItem } from './OptionsModal';

const styles = {
  inputLabel: {
    color: theme.themeDefault,
    fontSize: '.875rem',
    fontWeight: 600,
    '&.Mui-focused': {
      color: theme.themeDefault,
      fontWeight: 500,
    },
    transform: 'translate(14px, 10px) scale(1)',
    '&.MuiInputLabel-shrink': {
      color: '#262626',
      fontWeight: 500,
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
  },
  checkbox: {
    borderRadius: '20px',
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
};

const statusFilter = [
  { label: 'Needs Approval', value: JobStatusEnum.NEEDS_APPROVAL },
  { label: 'Completed', value: JobStatusEnum.COMPLETED },
  { label: 'In Progress', value: JobStatusEnum.IN_PROGRESS },
  { label: 'Upcoming', value: JobStatusEnum.UPCOMING },
  { label: 'Cancelled', value: JobStatusEnum.CANCELLED },
];
const JobStatusSelect = ({
  setBookMarked,
  showMobileModal,
  phoneOnly,
}: {
  setBookMarked: (arg: boolean) => void;
  showMobileModal: (
    title: string,
    items: OptionItem[],
    selectedItems: string[],
    showFilter: boolean,
    updateFilters: (selectedItem: string[]) => void,
    selectAll?: boolean,
  ) => void;
  phoneOnly: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const { currentAdmin } = useAuth();
  const [selectedItems, setSelectedItems] = useState<JobStatusEnum[]>([]);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const jobStatusInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.dashboardJobStatus,
  );
  useEffect(() => {
    setSelectedItems(jobStatusInStore || []);
  }, [jobStatusInStore]);

  const handleChange = (event: SelectChangeEvent<JobStatusEnum[]>) => {
    const value = event.target.value;
    if ((value as string[]).includes('all')) {
      if (selectedItems.length === statusFilter.length) {
        setSelectedItems([]);
        dispatch(setDashboardJobStatus(undefined));
      } else {
        setSelectedItems(statusFilter.map((filter) => filter.value));
        dispatch(
          setDashboardJobStatus(statusFilter.map((filter) => filter.value)),
        );
      }
    } else {
      setSelectedItems(value as JobStatusEnum[]);
      dispatch(
        setDashboardJobStatus(
          value.length > 0 ? (value as JobStatusEnum[]) : undefined,
        ),
      );
    }

    setBookMarked(false);
    if (jobStatusInStore && value.length > jobStatusInStore?.length) {
      logEvent(GAEvent.DashboardJobStatusFilter, value[value.length - 1], {
        filter_name: value[value.length - 1],
      });
    }
  };

  const getDisplayValue = (selected: string[]) => {
    if (selected.length === 0) return <span>Fill Status</span>;

    const maxCharLength = 10;
    const firstFilter = statusFilter.find(
      (item) => item.value === selected[0],
    )?.label;

    const displayText =
      firstFilter && firstFilter.length > maxCharLength
        ? `${firstFilter.substring(0, maxCharLength)}...`
        : firstFilter;

    const additionalCount = selected.length - 1;

    return (
      <Box display="flex" alignItems="center">
        <span>{displayText}</span>
        {additionalCount > 0 && <span>+{additionalCount}</span>}
      </Box>
    );
  };

  return (
    <FormControl style={{ width: '150px', marginRight: '10px' }}>
      <InputLabel id="job-status-multi-select-label" sx={styles.inputLabel}>
        Job Status
      </InputLabel>
      <Select
        labelId="job-status-multi-select-label"
        label="Job Status"
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={(selected) => getDisplayValue(selected as string[])}
        fullWidth
        sx={{
          minWidth: 120,
          height: 40,
          color: theme.themeDefault,
          fontSize: '.875rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
            borderWidth: '1px',
          },
        }}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: {
              maxHeight: 224,
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {
          phoneOnly
            ? showMobileModal(
                'Job Status',
                [
                  {
                    name: 'Needs Approval',
                    id: JobStatusEnum.NEEDS_APPROVAL,
                  },
                  { name: 'Completed', id: JobStatusEnum.COMPLETED },
                  { name: 'In Progress', id: JobStatusEnum.IN_PROGRESS },
                  { name: 'Upcoming', id: JobStatusEnum.UPCOMING },
                  { name: 'Cancelled', id: JobStatusEnum.CANCELLED },
                ],
                selectedItems,
                false,
                (selected: string[]) => {
                  handleChange({
                    target: { value: selected } as SelectChangeEvent<string[]>,
                  });
                },
                true,
              )
            : setOpen(true);
        }}
      >
        <MenuItem
          disableRipple
          style={{ cursor: 'default', pointerEvents: 'none' }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: '.875rem',
              fontWeight: 'bold',
              color: '#000',
              opacity: 100,
            }}
          >
            Job Status
          </Typography>
        </MenuItem>
        <MenuItem value="all">
          <Checkbox
            checked={selectedItems.length === statusFilter.length}
            sx={styles.checkbox}
          />
          <ListItemText
            primary="Select All"
            primaryTypographyProps={styles.menuItem}
          />
        </MenuItem>
        {statusFilter.map((filter) => (
          <MenuItem key={filter.label} value={filter.value}>
            <Checkbox
              checked={selectedItems.includes(filter.value)}
              sx={styles.checkbox}
            />
            <ListItemText
              primary={filter.label}
              primaryTypographyProps={styles.menuItem}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default JobStatusSelect;
