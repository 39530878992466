import React, { useState, useMemo, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  TextField,
  ListSubheader,
  InputAdornment,
  InputLabel,
  FormControl,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetCustomerAndSkillsQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import {
  CustomerOnboardingStatusEnum,
  GetCustomerAndSkillsQuery,
} from '@/types/graphql';
import theme from '@/styles/colors';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch } from '@/hooks/store';
import { setDashboardCustomerIds } from '@/store/slices/filtersSlice';
import Avatar from '@/components/Avatar';
import { useAppSelector } from '@/hooks/store';
import { Typography } from '@mui/material';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import { OptionItem } from './OptionsModal';
import { debounce } from 'lodash';
import { getDisplayText } from './helpers';

type Item = GetCustomerAndSkillsQuery['agency']['customers']['items'][0];

const styles = {
  inputLabel: {
    color: theme.themeDefault,
    fontSize: '.875rem',
    fontWeight: 600,
    '&.Mui-focused': {
      color: theme.themeDefault,
      fontWeight: 500,
    },
    transform: 'translate(14px, 10px) scale(1)',
    '&.MuiInputLabel-shrink': {
      color: '#262626',
      fontWeight: 500,
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
  },
  menuPaper: {
    maxHeight: '544px',
    maxWidth: '268px',
  },
  checkbox: {
    borderRadius: '20px',
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
};

const ClientsSelect = ({
  setBookMarked,
  showMobileModal,
  phoneOnly,
}: {
  setBookMarked: (arg: boolean) => void;
  showMobileModal: (
    title: string,
    items: OptionItem[],
    selectedItems: string[],
    showFilter: boolean,
    updateFilters: (selectedItem: string[]) => void,
    selectAll?: boolean,
  ) => void;
  phoneOnly: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const { currentAgency, currentAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const { data } = useGetCustomerAndSkillsQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: {
        status: [1],
        onboardingStatus: [CustomerOnboardingStatusEnum.COMPLETED],
      },
    },
  });

  const items: Item[] = useMemo(() => {
    if (data) {
      return data.agency.customers.items;
    }
    return [];
  }, [data]);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState(false);

  const filteredCustomers = useMemo(() => {
    return items.filter((customer) =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [items, searchTerm]);

  const customerIdsInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.customerIds,
  );
  useEffect(() => {
    setSelectedItems((customerIdsInStore as string[]) || []);
  }, [customerIdsInStore]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes('all')) {
      if (selectedItems.length === items.length) {
        setSelectedItems([]);
        dispatch(setDashboardCustomerIds(undefined));
      } else {
        setSelectedItems(items.map((item) => item.id)); // Store skill IDs
        dispatch(setDashboardCustomerIds(items.map((item) => item.id)));
      }
    } else {
      setSelectedItems(value);
      dispatch(setDashboardCustomerIds(value.length > 0 ? value : undefined));
    }
    setBookMarked(false);
    logEvent(GAEvent.DashbaordClientFilter, currentAdmin?.user.id);
  };

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return <span>Client</span>;
    }

    const selectedNames = selected
      .map((id) => items.find((item) => item.id === id)?.name)
      .filter(Boolean);

    if (selectedNames.length === 0) {
      return <span>Client</span>;
    }

    return (
      <Box display="flex" alignItems="center">
        <span>{getDisplayText(selectedNames)}</span>
      </Box>
    );
  };

  const handleSearchChange = debounce((value) => setSearchTerm(value), 300);

  return (
    <FormControl style={{ width: '150px', marginRight: '10px' }}>
      <InputLabel id="clients-multi-select-label" sx={styles.inputLabel}>
        Client
      </InputLabel>
      <Select
        labelId="clients-multi-select-label"
        label="Client"
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={renderValue}
        fullWidth
        sx={{
          minWidth: 90,
          maxWidth: 268,
          height: 40,
          color: theme.themeDefault,
          fontSize: '.875rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
            borderWidth: '1px',
          },
        }}
        open={open}
        onOpen={() => {
          phoneOnly
            ? showMobileModal(
                'Clients',
                items,
                selectedItems,
                true,
                (selected: string[]) => {
                  handleChange({
                    target: { value: selected } as SelectChangeEvent<string[]>,
                  });
                },
                true,
              )
            : setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setSearchTerm('');
          }, 150);
        }}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: styles.menuPaper,
          },
        }}
      >
        <MenuItem
          disableRipple
          style={{ cursor: 'default', pointerEvents: 'none' }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: '.875rem',
              fontWeight: 'bold',
              color: '#000',
              opacity: 100,
              marginBottom: '5px',
            }}
          >
            Clients
          </Typography>
        </MenuItem>
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              handleSearchChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuItem value="all">
          <Checkbox
            checked={selectedItems.length === items.length}
            sx={styles.checkbox}
          />
          <ListItemText
            primary="Select All"
            primaryTypographyProps={styles.menuItem}
          />
        </MenuItem>
        {filteredCustomers.map((customer) => (
          <MenuItem key={customer.id} value={customer.id}>
            <Checkbox
              checked={selectedItems.includes(customer.id)}
              sx={styles.checkbox}
            />
            <Avatar
              firstName={customer.name}
              css={{ height: '32px', width: '32px', marginRight: '8px' }}
              src={customer.logoUrl}
            />
            <ListItemText
              primary={customer.name}
              primaryTypographyProps={styles.menuItem}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClientsSelect;
