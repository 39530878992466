import { JobStatus } from './util';

import JobBadge from '@/components/JobBadge';

const JobBadgeComponent = ({
  jobStatus,
  style = { marginRight: '10px' },
}: {
  jobStatus: JobStatus | JobStatus[];
  style?: any;
}) => {
  const badges = {
    [JobStatus.CANCELLED]: <JobBadge label="Cancelled" status="danger" />,
    [JobStatus.UPCOMING]: <JobBadge label="Upcoming" status="success" />,
    [JobStatus.COMPLETED]: <JobBadge label="Completed" status="theme" />,
    [JobStatus.IN_PROGRESS]: <JobBadge label="In progress" status="warning" />,
    [JobStatus.NEEDS_APPROVAL]: (
      <JobBadge label="Needs Approval" status="needsApproval" />
    ),
    [JobStatus.UNPUBLISHED]: <JobBadge label="Private" />,
  };
  return (
    <>
      {Array.isArray(jobStatus) ? (
        jobStatus.map((jobStatus, index) => (
          <span key={index} style={style}>
            {badges[jobStatus]}
          </span>
        ))
      ) : (
        <span style={style}>{badges[jobStatus]}</span>
      )}
    </>
  );
};

export default JobBadgeComponent;
